export const THREE_D = ({ color, ...props }: React.ComponentPropsWithRef<'svg'>) => (
  <svg width='123' height='90' viewBox='0 0 123 90' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g opacity='0.9'>
      <path
        d='M0.604845 49.529C0.901582 48.4773 1.14372 47.4043 1.50218 46.3741C3.13304 41.7094 6.40665 38.3408 10.2785 35.4755C11.3871 34.6542 12.555 33.904 13.7396 33.1942C15.4773 32.1544 17.5972 32.653 18.637 34.2862C19.6791 35.9194 19.1901 37.9847 17.5378 39.1883C15.5936 40.6031 13.5188 41.8898 11.7906 43.5373C6.37578 48.6957 6.38528 54.1225 11.6933 59.364C15.6245 63.2453 20.4791 65.6169 25.5687 67.5516C32.434 70.1605 40.9516 72.0477 48.643 72.7006C47.6436 71.6845 46.7486 70.8371 45.9225 69.9255C44.5931 68.4608 44.6311 66.3457 45.9605 64.9997C47.3231 63.618 49.4644 63.5967 50.9837 64.9474C51.1023 65.0519 51.2115 65.1658 51.3231 65.2774C54.2881 68.2353 57.2602 71.1884 60.2134 74.1581C62.0104 75.9647 62.0009 77.9564 60.2015 79.7653C57.246 82.7327 54.281 85.6929 51.3089 88.6437C49.5902 90.3505 47.4442 90.4502 45.9486 88.9214C44.5172 87.4591 44.6311 85.2751 46.262 83.6561C47.4086 82.519 48.5955 81.427 50.0151 80.0763C47.8477 79.8151 46.0602 79.6276 44.2821 79.3807C34.4875 78.0134 24.9634 75.7059 16.1254 71.1053C11.1283 68.5035 6.63691 65.2774 3.43928 60.5106C1.96509 58.3148 1.17695 55.8602 0.600098 53.3177C0.600098 52.0548 0.600098 50.7895 0.600098 49.5266L0.604845 49.529Z'
        fill={color ?? 'rgb(var(--color-content1))'}
      />
      <path
        d='M122.148 53.3205C121.37 57.4677 119.478 61.0261 116.513 64.0315C113.852 66.7306 110.785 68.8695 107.468 70.6713C99.8503 74.809 91.6343 77.1425 83.1524 78.6594C81.3648 78.9799 79.5607 79.2268 77.7612 79.4689C75.6081 79.7585 73.8918 78.4766 73.5856 76.3971C73.3102 74.536 74.6514 72.7769 76.7049 72.4849C83.4871 71.5235 90.1696 70.1561 96.6123 67.7704C101.04 66.1324 105.341 64.2024 108.99 61.1591C110.637 59.7846 112.18 58.1798 113.405 56.4255C115.668 53.1875 115.537 49.7667 113.415 46.4362C111.898 44.0599 109.795 42.2818 107.525 40.6676C106.3 39.7964 105.685 38.6237 105.925 37.0973C106.148 35.6777 106.958 34.626 108.342 34.3364C109.203 34.1536 110.369 34.137 111.053 34.5762C115.91 37.7073 119.86 41.6433 121.571 47.3715C121.806 48.1572 121.958 48.9667 122.148 49.7667V53.3205Z'
        fill={color ?? 'rgb(var(--color-content1))'}
      />
      <path
        d='M57.1913 35.9267C59.8287 33.7831 62.6536 33.8258 65.5047 34.3291C69.3076 34.9985 71.5866 37.2846 72.0566 41.1018C72.2892 42.9962 72.2916 44.9831 71.9854 46.8632C71.3706 50.6448 69.0465 53.052 65.31 54.0419C62.8174 54.7018 60.294 54.7256 57.7871 54.1914C53.2079 53.2182 50.3806 49.9683 50.25 45.2632C50.1147 40.4086 50.1171 35.5469 50.25 30.6923C50.3758 26.0133 53.2031 22.8109 57.8109 21.7783C60.6429 21.1445 63.4655 21.1587 66.2406 22.0631C69.9581 23.2738 72.3866 26.4667 72.2964 29.954C72.2536 31.6086 71.6483 32.3943 70.0103 32.5653C69.0465 32.665 68.0305 32.5819 67.0809 32.3825C66.0768 32.1712 65.4904 31.4495 65.3836 30.3599C65.2317 28.8074 64.6216 28.1308 63.126 27.7154C59.6126 26.735 57.31 28.3801 57.196 31.9789C57.1557 33.2228 57.1889 34.4668 57.1889 35.9291L57.1913 35.9267ZM65.2839 44.1451C65.2673 44.1451 65.2507 44.1451 65.234 44.1451C65.234 43.9077 65.2459 43.6703 65.234 43.4353C65.0702 40.7267 63.8239 39.4947 61.2411 39.4828C58.5895 39.4709 57.3147 40.7172 57.2103 43.4994C57.1794 44.2828 57.2269 45.0852 57.3575 45.8567C57.6898 47.827 59.0667 48.8478 61.2791 48.8454C63.475 48.8407 64.821 47.7962 65.1509 45.7974C65.2412 45.2537 65.2435 44.6959 65.2839 44.1451Z'
        fill={color ?? 'rgb(var(--color-content1))'}
      />
      <path
        d='M99.848 38.0252C99.848 40.4323 99.9287 42.8418 99.829 45.2442C99.6249 50.17 96.6029 53.4935 91.7364 54.2864C89.0017 54.7327 86.2907 54.6709 83.6771 53.6905C80.3821 52.4537 78.2741 49.9872 78.0675 46.557C77.7423 41.173 77.5785 35.7391 77.9346 30.367C78.3144 24.6293 81.7803 21.7308 87.7578 21.4055C89.2771 21.3225 90.8533 21.4507 92.3418 21.7664C96.978 22.7492 99.6486 25.9516 99.8338 30.6875C99.9287 33.1302 99.8504 35.5801 99.8504 38.0252H99.848ZM84.8426 37.8566H84.8664C84.8664 40.1831 84.7904 42.5142 84.8877 44.8383C84.9803 47.027 86.3121 48.2709 88.413 48.3754C90.9578 48.4988 92.3655 47.4804 92.743 45.192C92.845 44.5724 92.8854 43.9386 92.8901 43.3095C92.9044 40.2709 92.9139 37.2347 92.8901 34.1961C92.8783 32.9379 92.8735 31.6655 92.686 30.4264C92.451 28.8738 91.4587 27.9148 89.8967 27.5824C86.9863 26.9628 84.9471 28.4987 84.8545 31.4685C84.788 33.5955 84.8426 35.7273 84.8426 37.859V37.8566Z'
        fill={color ?? 'rgb(var(--color-content1))'}
      />
      <path
        d='M40.4912 36.8573C44.89 39.0271 45.417 41.9897 44.7072 46.7968C44.1185 50.7755 41.1701 53.5007 36.8567 54.2461C34.1363 54.7162 31.4253 54.6995 28.776 53.8046C25.2342 52.6058 22.9837 49.7784 22.808 46.3363C22.7107 44.4301 23.2971 43.7131 25.1724 43.5375C25.8371 43.4758 26.5161 43.4781 27.1831 43.5351C29.1914 43.7037 29.5641 44.055 29.7588 46.0277C29.8656 47.1197 30.4472 47.9054 31.4419 48.0503C32.8235 48.252 34.2573 48.2829 35.6484 48.1595C36.757 48.0621 37.5333 47.2408 37.6662 46.1203C37.8134 44.8811 37.8728 43.6039 37.7517 42.3648C37.6354 41.1541 36.7784 40.4134 35.5915 40.1357C35.0953 40.0194 34.5778 39.9553 34.0674 39.9339C33.1155 39.8959 32.2158 39.7654 31.8312 38.7351C31.3825 37.5339 31.3042 36.2829 32.1351 35.2502C32.4698 34.8348 33.2009 34.6093 33.7778 34.5428C36.6455 34.2176 38.1909 31.1766 36.6075 28.6959C36.2538 28.1404 35.4704 27.7202 34.8033 27.5398C34.0674 27.3404 33.2318 27.4235 32.4508 27.5042C31.1309 27.6419 30.6158 28.2164 30.4377 29.5529C30.1719 31.5469 29.519 32.1499 27.5392 32.2282C23.6698 32.3849 22.6609 31.0888 23.7742 27.3404C24.6858 24.2733 26.9125 22.5973 29.887 21.8258C32.5766 21.1279 35.2971 21.1706 37.9843 21.8472C42.2241 22.9107 44.3583 26.2674 43.7387 30.849C43.4182 33.211 42.7535 35.414 40.4936 36.8573H40.4912Z'
        fill={color ?? 'rgb(var(--color-content1))'}
      />
      <path
        d='M119.117 10.6613C119.117 16.577 114.326 21.3485 108.403 21.3224C102.571 21.2987 97.7729 16.4868 97.7729 10.6613C97.7729 4.78825 102.628 -0.0260071 108.52 0.00010573C114.378 0.0262186 119.114 4.79063 119.114 10.6589L119.117 10.6613ZM111.993 10.6731C111.997 8.70993 110.423 7.11704 108.472 7.1123C106.552 7.10755 104.904 8.73604 104.897 10.6518C104.89 12.5628 106.533 14.2079 108.451 14.215C110.402 14.2221 111.988 12.6363 111.993 10.6731Z'
        fill={color ?? 'rgb(var(--color-content1))'}
      />
    </g>
  </svg>
);

export const HUNGARIYA_FLAG = ({ color, ...props }: React.ComponentPropsWithRef<'svg'>) => (
  <svg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clip-path='url(#clip0_2339_18327)'>
      <path
        d='M12.9303 24C19.5566 24 24.9279 18.6276 24.9279 12C24.9279 5.37237 19.5566 0 12.9303 0C6.30394 0 0.932617 5.37237 0.932617 12C0.932617 18.6276 6.30394 24 12.9303 24Z'
        fill='#B8C2C7'
      />
      <mask
        id='mask0_2339_18327'
        // style='mask-type:luminance'
        maskUnits='userSpaceOnUse'
        x='1'
        y='0'
        width='23'
        height='24'
      >
        <path
          d='M12.9304 23.0027C19.0041 23.0027 23.9259 18.0753 23.9259 12.0004C23.9259 5.92545 19.0041 0.998047 12.9304 0.998047C6.85662 0.998047 1.93018 5.92545 1.93018 12.0004C1.93018 18.0753 6.85662 23.0027 12.9304 23.0027Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask0_2339_18327)'>
        <path d='M23.9306 7.91211H1.93018V16.0901H23.9306V7.91211Z' fill='white' />
        <path d='M23.9306 15.9492H1.93018V23.9867H23.9306V15.9492Z' fill='#008C55' />
        <path d='M23.9306 0.0195312H1.93018V8.057H23.9306V0.0195312Z' fill='#ED1C24' />
      </g>
    </g>
    <defs>
      <clipPath id='clip0_2339_18327'>
        <rect width='24' height='24' fill='white' transform='translate(0.932617)' />
      </clipPath>
    </defs>
  </svg>
);

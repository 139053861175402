import { Icon } from '@/core/icon';
import { Card, CardContent, Typography } from '@/core/ui';
import { cn } from '@/lib';

const Chart2 = () => {
  return {
    /* <div className='flex flex-row'>
                <div className='flex flex-col space-y-4'>
                  <Typography>GRUPO F · CLASSIFICAÇÃO</Typography>
                  {Array.from({ length: 4 }).map((_, index) => (
                    <div key={index} className='flex flex-row'>
                      <Typography className=''>{index}</Typography>
                      <Icon name='HUNGARIYA_FLAG' className='' />
                      <Typography className='text-2xl leading-[27.6px] tracking-[1.2px]'>HUNGARIYA</Typography>
                    </div>
                  ))}
                </div>
                <div className='flex flex-col space-y-4 pl-16'>
                  {Array.from({ length: 5 }).map((_, index) => (
                    <div key={index} className='flex flex-row space-x-12'>
                      {Array.from({ length: 7 }).map((_, index) => (
                        <Typography key={index}>{index}</Typography>
                      ))}
                    </div>
                  ))}
                </div>
              </div> */
  };
};
Chart2;

const Group = ({ name }: { name: boolean; scores: boolean }) => {
  return (
    <div className='flex flex-col'>
      {name ? (
        <Typography className='text-2xl leading-[30px] tracking-[1.2px] font-semibold'>
          OITAVOS DE FINAL
        </Typography>
      ) : (
        <></>
      )}
      <Typography className='text-2xl leading-[30px] tracking-[1.2px]'>12/12/24</Typography>
      <Typography className='text-2xl leading-[30px] tracking-[1.2px]'>20:00</Typography>
      <div className='flex mt-4 space-x-3 items-center'>
        <Typography className='text-3xl leading-[34.5px] font-bold'>0</Typography>
        {name ? (
          <Typography className='text-3xl leading-[34.5px] tracking-[1.5px] font-bold'>[3]</Typography>
        ) : (
          <></>
        )}
        <Icon name='PORTUGAL_FLAGBIG' />
        <Typography className='text-2xl leading-[27.6px] tracking-[1.2px] font-semibold'>PORTUGAL</Typography>
      </div>
      <div className='flex mt-4 space-x-3 items-center'>
        <Typography className='text-3xl leading-[34.5px] font-bold'>0</Typography>
        {name ? (
          <Typography className='text-3xl leading-[34.5px] tracking-[1.5px] font-bold'>[5]</Typography>
        ) : (
          <></>
        )}
        <Icon name='PORTUGAL_FLAGBIG' />
        <Typography className='text-2xl leading-[27.6px] tracking-[1.2px] font-semibold'>PORTUGAL</Typography>
      </div>
    </div>
  );
};

const Chart = () => {
  return (
    <div className='flex flex-col mt-14 space-y-4'>
      <div className='flex justify-between'>
        <Typography>GRUPO F · CLASSIFICAÇÃO</Typography>
        <div className='flex space-x-11 items-center'>
          {['P', 'J', 'V', 'E', 'D', 'GM', 'GS'].map((item, index) => (
            <div className='' key={index}>
              <Typography>{item}</Typography>
            </div>
          ))}
        </div>
      </div>
      {Array.from({ length: 4 }).map((_, index) => (
        <div key={index} className={cn('border-content3', { 'border-b': index !== 3 })}>
          <div className='flex flex-row space-x-12'>
            <div className='flex space-x-4'>
              <Typography className='text-2xl leading-[27.6px] tracking-[1.2px]'>{index + 1}</Typography>
              <div className='pl-3'>
                <Icon name='HUNGARIYA_FLAG' className='' />
              </div>
              <Typography className='text-2xl leading-[27.6px] tracking-[1.2px]'>HUNGARIYA</Typography>
            </div>
            <div className='pl-24 flex space-x-12'>
              {Array.from({ length: 7 }).map((_, index) => (
                <div className='' key={index}>
                  <Typography>{index}</Typography>
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

const Eliminations = () => {
  return (
    <div className='flex flex-col items-center -mt-16'>
      <Typography className='text-[34px] leading-[34px] tracking-[1.7px] font-semibold'>
        ELIMINATORIAS
      </Typography>
      <div className='flex w-full justify-center items-center mt-14 space-x-8'>
        {Array.from({ length: 4 }).map((_, index) => (
          <div
            key={index}
            className='flex bg-chalk rounded-[50px] pl-16 items-center shadow-board w-[375px] h-[311px]'
          >
            <Group name={true} scores={false} />
          </div>
        ))}
      </div>
    </div>
  );
};

export const Section2 = () => {
  return (
    <Card className='w-full shadow-board rounded-[40px]'>
      <CardContent>
        <div className='w-full h-full flex flex-col pt-24'>
          <div className='flex flex-row justify-between space-x-32 px-24'>
            <div>
              <Typography className='text-[34px] leading-[34px] tracking-[1.7px] font-semibold'>
                FASE FINAL
              </Typography>
              <Chart />
            </div>
            <div>
              <Typography className='text-[34px] leading-[34px] tracking-[1.7px] font-semibold'>
                GRUPOS
              </Typography>
              <div className='flex w-full mt-14 space-x-20'>
                {Array.from({ length: 3 }).map((_, index) => (
                  <Group key={index} name={false} scores />
                ))}
              </div>
            </div>
          </div>
          <div className='bg-match bg-bottom -mb-20 bg-no-repeat h-[30rem] mt-36'>
            <Eliminations />
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

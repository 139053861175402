import { APP_TYPE, COOKIES_KEY, LANGUAGES, LOCAL_KEY } from '@/config';
import { Storage } from '@/utils';
import type { SerializedError } from '@reduxjs/toolkit';
import type { BaseQueryFn, FetchBaseQueryError } from '@reduxjs/toolkit/query';
import axios, { type AxiosRequestConfig, type AxiosError, type AxiosRequestHeaders } from 'axios';

type AxiosArgs<T> = {
  url: string;
  method: AxiosRequestConfig['method'];
  data?: AxiosRequestConfig['data'];
  body?: AxiosRequestConfig['data'];
  params?: AxiosRequestConfig['params'];
  mockData?: T;
  fullUrl?: boolean;
  forceMock?: boolean;
  isProtected?: boolean;
};

// type Return1 = BaseQueryFn<
//   string | FetchArgs,
//   unknown,
//   FetchBaseQueryError | SerializedError,
//   {},
//   FetchBaseQueryMeta
// >;
type Return2<T> = BaseQueryFn<AxiosArgs<T>, T, FetchBaseQueryError | SerializedError>;

const fetchTokens = async (): Promise<{ token: string; locale: LANGUAGES; jwtToken: string | null }> => {
  try {
    const TOKEN_CONSTANT = COOKIES_KEY.FOOTBALL_JWT_TOKEN;
    const locale = Storage.local.get<LANGUAGES>(LOCAL_KEY.CURRENT_LOCALE) ?? LANGUAGES.PORTUGUESE;
    const token = Storage.session.get<string>(TOKEN_CONSTANT);
    const jwtToken = Storage.session.get<string>(COOKIES_KEY.FOOTBALL_JWT_TOKEN);
    if (token && jwtToken) {
      return { token, jwtToken, locale };
    }

    return { token: '', jwtToken, locale };
  } catch (error) {
    // biome-ignore lint/complexity/noUselessCatch: <explanation>
    throw error;
  }
};

export const fetchCustomBaseQuery = <T>({
  baseUrl = '',
  mock,
}: {
  baseUrl: string;
  mock?: boolean;
}): Return2<T> => {
  // if (!mock) {
  //   return fetchBaseQuery({
  //     baseUrl,
  //     prepareHeaders: async (headers) => {
  //       const { token, jwtToken } = await fetchTokens();
  //       // headers.set('X-CSRF-TOKEN', token);
  //       headers.set('Content-Type', 'application/json');
  //       return headers;
  //     },
  //     // credentials: 'include',
  //   });
  // }
  const res = async ({
    url,
    method,
    forceMock,
    body,
    params,
    mockData,
    fullUrl = false,
    isProtected = true,
  }: AxiosArgs<T>) => {
    try {
      if (mockData && (mock || forceMock)) {
        const newRes = await new Promise((res) => setTimeout(() => res(mockData), 2000));
        return { data: newRes as T };
      }

      const { jwtToken, locale } = await fetchTokens();

      const headers: Partial<AxiosRequestHeaders> = {
        'Content-Type': body instanceof FormData ? undefined : 'application/json',
        'Active-App': APP_TYPE.SHIRT,
        locale: locale,
      };

      if (isProtected && !jwtToken) {
        // Toaster.error('You are unauthorized');
        throw new Error('You are unauthorized');
      }

      if (jwtToken) {
        headers.Authorization = `Bearer ${jwtToken}`;
      }

      let finalUrl: string;

      if (fullUrl) {
        finalUrl = url;
      } else {
        finalUrl = baseUrl + url;
      }

      const result = await axios({
        url: finalUrl,
        method,
        data: body,
        headers: headers as never,
        params,
      });
      return { data: result.data as T };
    } catch (axiosError) {
      const err = axiosError as AxiosError;
      // Toaster.error(
      //   (err.response?.data as IResponse<void>)?.message ?? err?.message ?? 'Something went wrong',
      // );
      throw err;
    }
  };

  return res;
};

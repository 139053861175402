import { OliveBox, Stack } from '@/core/blocks';
import { Card, CardContent, Typography } from '@/core/ui';

export const Section4 = () => {
  return (
    <Card className='rounded-[40px] shadow-board w-full'>
      <CardContent className='p-0'>
        <div className='grid grid-cols-3'>
          <div className='col-span-2 flex flex-col space-y-16 py-24 px-28'>
            <div className='flex flex-col space-y-6'>
              <Typography className='text-[34px] leading-[34px] font-semibold tracking-[1.7px]'>
                ESTATÍSTICAS
              </Typography>
              <Typography className='text-[26px] block max-h-44 overflow-y-auto no-scrollbar leading-[29.9px] tracking-[1.3px]'>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud. Lorem ipsum dolor sit
                amet, consectetur adipiscing elit, sed do eiusmod tempordo eiusmod tempordo eiusmod
                temporadipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud. Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                sed do eiusmod tempordo eiusmod tempordo eiu.
              </Typography>
            </div>
            <div className='flex space-x-32'>
              <div className='flex flex-col space-y-10'>
                <Typography className='text-[34px] leading-[32.3px] font-semibold tracking-[1.7px]'>
                  JOGOS
                </Typography>
                <div className='flex flex-row space-x-12'>
                  <OliveBox text='JOGOS' value={2} />
                  <div className='flex flex-col justify-between items-start'>
                    <RowItem name='vitórias' value={2} />
                    <RowItem name='empates' value={0} />
                    <RowItem name='derrotas' value={0} />
                  </div>
                </div>
              </div>
              <div className='flex items-end'>
                <Stack upperClass='w-[5px] h-[137px]' lowerClass='w-[5px] h-[73px]' />
              </div>
              <GoalData />
            </div>
          </div>
          <Card className='col-span-1 rounded-[40px] shadow-board p-24'>
            <CardContent className='p-0 flex flex-col justify-center space-y-8 items-center'>
              <Typography className='text-[34px] text-center leading-[32.3px] font-semibold tracking-[1.7px] uppercase'>
                Melhor Marcador
              </Typography>
              <PlayerInformation />
              <div className='bg-olive rounded-[25px] w-[186px] h-[262px] flex flex-col items-center'>
                <div className='mt-3'>
                  <Typography className='text-chalk text-[125px] font-bold'>3</Typography>
                </div>
                <Typography className='text-[26px] text-chalk tracking-[1.82px] leading-[26px] text-center uppercase font-semibold'>
                  golos marcados
                </Typography>
              </div>
            </CardContent>
          </Card>
        </div>
      </CardContent>
    </Card>
  );
};

const PlayerInformation = () => {
  const single = true;

  if (single) {
    return (
      <div className='flex flex-col items-center space-y-5'>
        <img src='/shirts/player-icon.png' alt='Player Icon' />
        <div className='max-w-52 text-center'>
          <Typography className='text-[24px] text-center leading-[24px] font-semibold tracking-[1.7px] uppercase'>
            cristiano ronaldo
          </Typography>
        </div>
      </div>
    );
  }

  return (
    <div className='flex flex-col items-center space-y-5'>
      {Array.from({ length: 3 }).map((_, index) => (
        <div key={index} className='flex items-center space-x-4'>
          <img src='/shirts/player-icon.png' alt='Player Icon' className='w-[62.83px] h-[62.83px]' />
          <Typography className='text-[24px] text-center leading-[24px] font-semibold tracking-[1.8px] uppercase'>
            cristiano ronaldo
          </Typography>
        </div>
      ))}
    </div>
  );
};

const RowItem = ({ value, name }: { value: number; name: string }) => {
  return (
    <div className='flex flex-row items-center justify-center space-x-5'>
      <div className='border border-content1 rounded-lg px-5 pt-1'>
        <Typography className='text-[36px] font-semibold'>{value}</Typography>
      </div>
      <Typography className='text-[26px] tracking-[1.82px] uppercase leading-[26px] font-semibold'>
        {name}
      </Typography>
    </div>
  );
};

const GoalItem = () => {
  return (
    <div className='flex flex-row space-x-6'>
      <div className='bg-olive w-[90px] h-[90px] flex justify-center items-center rounded-2xl'>
        <Typography className='text-[56px] text-chalk font-bold'>4</Typography>
      </div>
      <div className='flex flex-col justify-center'>
        <Typography className='break-keep leading-[26px] text-[26px] tracking-[1.82px] uppercase font-semibold'>
          golos marcados
        </Typography>
        <Typography className='text-lg leading-[18px] uppercase tracking-[1.26px]'>
          média 2 por jogo
        </Typography>
      </div>
    </div>
  );
};

export const GoalData = () => {
  return (
    <div className='flex flex-col space-y-10'>
      <Typography className='text-3xl tracking-[1.5px] font-semibold'>GOLOS</Typography>
      <div className='flex flex-col space-y-[30px]'>
        <GoalItem />
        <GoalItem />
      </div>
    </div>
  );
};

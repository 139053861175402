import { LANGUAGES } from '@/config';
import { Icon } from '@/core/icon';
import { Popover, PopoverContent, PopoverTrigger, Typography } from '@/core/ui';
import { cn } from '@/lib';
import { useTranslation } from 'react-i18next';

export const LanguageSwitcher = () => {
  const { i18n } = useTranslation('translation');

  const changeLanguage = (lng: LANGUAGES) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div className='flex flex-row space-x-8'>
      <Popover>
        <PopoverTrigger>
          <div className='h-[108px] w-[108px] bg-danger flex items-center justify-center text-center rounded-full'>
            <Icon name='GLOBE' className='' />
          </div>
        </PopoverTrigger>
        <PopoverContent side='left' className='bg-transparent shadow-none border-none w-full mr-2'>
          <div className='flex flex-row space-x-6'>
            {Object.entries(LANGUAGES).map(([, lng]) => (
              <button
                type='button'
                onClick={() => changeLanguage(lng)}
                key={lng}
                className={cn(
                  'h-[108px] w-[108px] flex items-center justify-center',
                  'bg-chalk text-center rounded-full border border-content2',
                  { 'border-danger': lng === i18n.language },
                )}
              >
                <Typography
                  className={cn('text-[37px] text-content2 pt-0.5 font-semibold uppercase', {
                    'text-danger': lng === i18n.language,
                  })}
                >
                  {lng}
                </Typography>
              </button>
            ))}
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
};

import { Typography } from '@/core/ui';
import { cn } from '@/lib';

export const Loading = () => (
  <div
    className={cn(
      'flex h-screen w-full items-center justify-center',
      'bg-objects bg-apple bg-cover bg-center bg-no-repeat',
    )}
  >
    <div
      className={cn(
        'w-full lg:max-w-[100rem] max-w-xl rounded-md bg-chalk py-[48rem] shadow-xl',
        'flex flex-col items-center justify-center space-y-8',
      )}
    >
      <img alt='Football Logo' className='h-[25rem] w-full' height='21' src='/logo.svg' width='100%' />
      <Typography className='text-[10rem] font-medium text-content1'>....</Typography>
    </div>
  </div>
);

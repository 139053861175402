import { Typography } from '@/core/ui';
import { cn } from '@/lib';

interface IProps {
  value: number;
  text: string;
  textClassName?: string;
  valueClassName?: string;
  wrapperClass?: string;
}

export const OliveBox = ({ value, text, textClassName, wrapperClass, valueClassName }: IProps) => {
  return (
    <div
      className={cn('bg-olive rounded-[20px] w-[150px] h-[210px] flex flex-col items-center', wrapperClass)}
    >
      <div className='mt-3'>
        <Typography className={cn('text-chalk text-[100px] font-bold', valueClassName)}>{value}</Typography>
      </div>
      <Typography
        className={cn(
          'text-[26px] text-chalk tracking-[1.82px] leading-[26px] text-center uppercase font-semibold',
          textClassName,
        )}
      >
        {text}
      </Typography>
    </div>
  );
};

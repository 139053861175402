import { Card, CardContent, Typography } from '@/core/ui';

export const Section1 = () => {
  return (
    <div className='grid grid-cols-12 gap-x-8'>
      <Card className='rounded-[40px] shadow-board col-span-4'>
        <CardContent className='flex h-full items-center justify-center'>
          <img src='/shirts/uefa-france.png' alt='UEFA France Trophy' />
        </CardContent>
      </Card>
      <Card className='rounded-[40px] shadow-board col-span-8 pt-28 pb-16 px-16'>
        <CardContent className='flex flex-col items-center justify-center space-y-12'>
          <div className='flex flex-row space-x-8 justify-center items-center'>
            {Array.from({ length: 3 }).map((_, index) => (
              <div key={index} className='flex flex-col space-y-8 items-center'>
                <Typography className='text-[34px] font-medium leading-[34px] tracking-[1.7px] max-w-40 text-center'>
                  CAMISOLA PRINCIPAL
                </Typography>
                <img src='/shirts/jersey.png' className='border w-72 bg-cover h-72' alt='' />
              </div>
            ))}
          </div>
          <Typography className='text-[26px] text-center leading-[29.9px] tracking-[1.3px] block max-w-[882px]'>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
            labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud. Lorem ipsum dolor sit amet,
            consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
            enim ad minim veniam, quis nostrud.
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
};

import { Button, Typography } from '@/core/ui';
import { LanguageSwitcher } from './lang-switcher';

export const Footer = () => {
  return (
    <div className='w-full'>
      <div className='flex flex-row justify-between items-center px-44'>
        <div className='flex flex-row space-x-10 w-full pl-16'>
          <Button className='bg-danger rounded-full px-16 py-14 items-center justify-center'>
            <Typography className='font-semibold text-[33px] leading-[31px] text-center text-chalk'>
              FUTEBOL
            </Typography>
          </Button>
          <Button className='bg-chalk rounded-full px-16 py-14 items-center justify-center border-2 border-content2'>
            <Typography className='font-semibold text-[33px] leading-[31px] text-center text-content2'>
              MASCULINO
            </Typography>
          </Button>
          <Button className='bg-chalk rounded-full px-16 py-14 items-center justify-center border-2 border-content2'>
            <Typography className='font-semibold text-[33px] leading-[31px] text-center text-content2'>
              FEMINIO
            </Typography>
          </Button>
        </div>
        <LanguageSwitcher />
      </div>
    </div>
  );
};

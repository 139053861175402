import { Loading, Maintenance, NotFound, ScreenSaver } from '@/core/blocks';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { ROUTES, envVars } from './config';
import { useIdle } from './core';
import { Layout } from './layout';
import Home from './pages/home';
import Shirts from './pages/shirts/id';
import { PrivateRoutes } from './router';

function App() {
  const { onReset, isIdle, globalLoading } = useIdle();

  if (envVars.underMaintenance) {
    return <Maintenance />;
  }

  if (globalLoading) {
    return <Loading />;
  }

  if (isIdle) {
    return <ScreenSaver reset={onReset} />;
  }

  return (
    <>
      <Router>
        <Routes>
          <Route element={<PrivateRoutes />}>
            <Route element={<Layout />}>
              <Route path={ROUTES.HOME} Component={Home} />
            </Route>
            <Route path={ROUTES.SHIRTS.view} Component={Shirts} />
          </Route>
          <Route
            path={ROUTES.LOGIN}
            element={<div className='h-screen flex justify-center items-center text-8xl'>Login</div>}
          />
          <Route path='*' Component={NotFound} />
        </Routes>
      </Router>
    </>
  );
}

export default App;

import {
  CHEVRON_LEFT,
  CHEVRON_RIGHT,
  GLOBE,
  HOME,
  HUNGARIYA_FLAG,
  OBJECT_LOGO_TEXT,
  PAUSE,
  PLAY,
  PLUS,
  PORTUGAL_FLAGBIG,
  THREE_D,
  X_ICON,
} from './collections';

export enum ICON_NAMES {
  CHEVRON_RIGHT = 'CHEVRON_RIGHT',
  GLOBE = 'GLOBE',
  X_ICON = 'X_ICON',
  PLUS = 'PLUS',
  HUNGARIYA_FLAG = 'HUNGARIYA_FLAG',
  PORTUGAL_FLAGBIG = 'PORTUGAL_FLAGBIG',
  HOME = 'HOME',
  CHEVRON_LEFT = 'CHEVRON_LEFT',
  THREE_D = 'THREE_D',
  PLAY = 'PLAY',
  PAUSE = 'PAUSE',
  OBJECT_LOGO_TEXT = 'OBJECT_LOGO_TEXT',
}
export type ICON_TYPE = keyof typeof ICON_NAMES | ICON_NAMES;

export const ICON_COMPONENTS: Record<ICON_NAMES, (props: React.ComponentPropsWithRef<'svg'>) => JSX.Element> =
  {
    [ICON_NAMES.HUNGARIYA_FLAG]: HUNGARIYA_FLAG,
    [ICON_NAMES.PORTUGAL_FLAGBIG]: PORTUGAL_FLAGBIG,
    [ICON_NAMES.CHEVRON_RIGHT]: CHEVRON_RIGHT,
    [ICON_NAMES.CHEVRON_LEFT]: CHEVRON_LEFT,
    [ICON_NAMES.HOME]: HOME,
    [ICON_NAMES.GLOBE]: GLOBE,
    [ICON_NAMES.X_ICON]: X_ICON,
    [ICON_NAMES.PLUS]: PLUS,
    [ICON_NAMES.PAUSE]: PAUSE,
    [ICON_NAMES.PLAY]: PLAY,
    [ICON_NAMES.THREE_D]: THREE_D,
    [ICON_NAMES.OBJECT_LOGO_TEXT]: OBJECT_LOGO_TEXT,
  };

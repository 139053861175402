import { envVars } from '@/config';
import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import { globalApi, globalReducer } from './global';

export const store = configureStore({
  reducer: {
    global: globalReducer,
    [globalApi.reducerPath]: globalApi.reducer,
  },
  devTools: !envVars.prod,
  middleware(getDefaultMiddleware) {
    return getDefaultMiddleware({ serializableCheck: false }).concat(globalApi.middleware);
  },
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

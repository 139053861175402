import { Icon } from '@/core/icon';
import { cx } from '@/lib';
import { Outlet } from 'react-router-dom';
import { Footer } from './footer';

export const Layout = () => {
  return (
    <main
      className={cx(
        'bg-contain bg-top bg-no-repeat bg-apple bg-phases',
        'flex flex-col justify-between items-center',
        'h-screen w-full items-center py-56',
      )}
    >
      <Icon name='OBJECT_LOGO_TEXT' className='w-[366px] h-[400px]' />
      <Outlet />
      <Footer />
    </main>
  );
};

import type { IPicture } from '@/types';

export const playerGallery: IPicture[] = [
  {
    caption: 'Punakaiki Coast',
    path: 'https://images.unsplash.com/photo-1707007694363-b8afb46ed639?q=80&w=2971&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    mime_type: 'image/jpeg',
  },
  {
    caption: 'Golden Hour',
    path: 'https://images.unsplash.com/photo-1522747776116-64ee03be1dad?q=80&w=2970&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    mime_type: 'image/jpeg',
  },
  {
    caption: 'Golden Hour',
    path: 'https://images.unsplash.com/photo-1522747776116-64ee03be1dad?q=80&w=2970&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    mime_type: 'image/jpeg',
  },
];

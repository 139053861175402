import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration'; // Import the UTC plugin
import utc from 'dayjs/plugin/utc'; // Import the UTC plugin

dayjs.extend(utc);
dayjs.extend(duration);

export const getUnixTimestamp = (date: string | Date): number => {
  const inputDate = dayjs(date);
  const unixTimestamp = inputDate.valueOf() / 1000;
  return unixTimestamp;
};

export const getFormattedDate = (
  date: number | string | Date,
  format: string | undefined = 'DD MMM YYYY',
) => {
  const formatted = dayjs(date).format(format);
  return formatted;
};

export const convertToSeconds = (date: string | Date) => {
  const endDate = dayjs(date); // Replace with your actual end date
  const hour = endDate.hour();
  const minute = endDate.minute();
  const seconds = endDate.second();
  const finalSeconds = hour * 3600 + minute * 60 + seconds;
  return finalSeconds;
};

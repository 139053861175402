import { ROUTES } from '@/config';
import { Icon } from '@/core/icon';
import { Button, Card, CardContent, Carousel, CarouselContent, CarouselItem, Typography } from '@/core/ui';
import { Link } from 'react-router-dom';

const Home = () => {
  return (
    <div className='py-36 h-full flex items-center justify-between flex-col w-full'>
      <div className='flex flex-col space-y-16'>
        <Typography className='text-[120px] max-w-[90rem] leading-[114px] font-bold text-center tracking-[8.4px]'>
          FASES FINAIS COMPETIÇÕES OFICIAIS
        </Typography>
        <div className='flex flex-col space-y-4 pt-12'>
          <Typography className='text-[85px] leading-[85px] text-center tracking-[12.75px]'>
            CAMPEONATO EUROPEU
          </Typography>
          <Typography className='text-[65px] leading-[65px] text-center tracking-[12.75px]'>2024</Typography>
        </div>
      </div>
      <div className='max-h-[80rem] my-auto relative'>
        <div className='absolute z-[10] top-[52%] rounded-full bg-olive/60 left-[28%] backdrop-blur-lg'>
          <Icon name='PLUS' className='h-52 w-52' />
        </div>
        <img
          src='/3d/jersey.png'
          alt=''
          className='-mt-[20rem] animate-float object-top object-cover w-full h-[120rem]'
        />
      </div>
      <div className='flex flex-col w-full px-44 pb-28'>
        <div className='flex justify-center'>
          <span />
          <div className='flex flex-row space-x-10 items-center'>
            <Icon name='CHEVRON_LEFT' className='w-14 h-14' color='rgb(var(--color-content1))' />
            <Icon name='THREE_D' />
            <Icon name='CHEVRON_RIGHT' className='w-14 h-14' color='rgb(var(--color-content1))' />
          </div>
        </div>
        <div className='flex justify-end'>
          <span />
          <Button className='bg-danger rounded-full px-16 py-14 items-center justify-center'>
            <Typography className='font-semibold text-[33px] leading-[31px] text-center text-chalk'>
              QUIZ - DESAFIO
            </Typography>
          </Button>
        </div>
      </div>
      <div className='flex flex-col w-full'>
        <ShirtCarousel />
      </div>
    </div>
  );
};

export default Home;

const ShirtCarousel = () => {
  return (
    <Carousel className='w-full'>
      <CarouselContent className='w-full px-20 pl-32'>
        {Array.from({ length: 8 }).map((_, index) => (
          <CarouselItem key={index} className='basis-[21.8%]'>
            <Link to={`${ROUTES.SHIRTS.main}/${index}`} className='flex justify-center items-center'>
              <Card className='rounded-[26px] w-[383px] h-[383px]'>
                <CardContent className='flex flex-col space-y-8 items-center justify-center h-full p-0'>
                  <img src='/shirts/portugal-shirt.png' alt='' />
                  <Typography className='text-[37px] leading-[35px] text-center tracking-[1.85px] font-semibold max-w-80'>
                    CAMPEONATO EUROPEU 2024
                  </Typography>
                </CardContent>
              </Card>
            </Link>
          </CarouselItem>
        ))}
      </CarouselContent>
    </Carousel>
  );
};

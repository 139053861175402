import { LazyImage, Typography } from '@/core/ui';

export const Section5 = () => {
  return (
    <div className=''>
      <LazyImage
        inView
        src='/shirts/team.png'
        alt='hello'
        className='overflow-hidden h-[1002px] rounded-[40px]'
      />
      <div className='mt-6'>
        <Typography className='text-[26px] leading-[29.9px] tracking-[1.3px]'>
          Jogadores: Cristiano Ronaldo; Ruben Neves; Rui Patricio; João Cancelo; João Félix; João Palhinha;
          Diogo Costa; Francisco Conceição; Pepe; Diogo Jota; Bernardo Silva; Matheus Nunes; Ruben Dias; Bruno
          Fernandes; Diogo Dalot; Danilo; Gonçalo Ramos; Nuno Mendes; Vitinha; António Silva; João Neves;
          Gonçalo Inácio; José Sá; Nelson Semedo; Pedro Neto. Equipa Técnica: Roberto Martinez; Jesus Seba
          Hernandez; Ricardo Carvalho; Richard Evans; Anthony Barry; Ricardo Pereira; Iñaki Vergara; Stijn
          Campo.
        </Typography>
      </div>
    </div>
  );
};

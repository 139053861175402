import { Typography } from '@/core/ui';
import { cx } from '@/lib';
import { useTranslation } from 'react-i18next';
import { Icon } from '../icon';

export const ScreenSaver = ({ reset }: { reset: () => void }) => {
  const { t } = useTranslation('translation');

  const handleClick = () => {
    reset();
  };

  return (
    <div
      className={cx(
        'bg-apple bg-cover bg-screenSaver ',
        'flex flex-col justify-center items-center',
        'h-screen',
      )}
    >
      <Icon name='OBJECT_LOGO_TEXT' className='w-[40rem] h-auto absolute top-[23%]' />
      <div className='flex flex-col space-y-20 absolute top-[49%] items-center'>
        <Typography className='font-united-sans text-[200px] font-bold leading-[190px] uppercase tracking-[14px]'>
          {t('screenSaver.title')}
        </Typography>
        <Typography className='font-united-sans text-[73px] font-medium leading-[73px] uppercase tracking-[10.95px]'>
          {t('screenSaver.subtitle')}
        </Typography>
      </div>
      <div className='absolute bottom-[16%] flex justify-center items-center p-10 rounded-full bg-danger/20'>
        <button
          onClick={handleClick}
          type='button'
          className='justify-center items-center flex rounded-full w-44 h-44 bg-danger'
        >
          <Icon name='CHEVRON_RIGHT' className='h-20 w-20' />
        </button>
      </div>
    </div>
  );
};

import { GallerySlider } from '@/core/blocks';
import { playerGallery } from '@/mocks';

export const Section3 = () => {
  return (
    <div className='h-[756px]'>
      <GallerySlider slides={playerGallery} />
    </div>
  );
};
